import React from 'react';
import './styles/MenuCard.css'

export default function MenuCard(porps){
    // console.log('MenuCard props..\n',porps);
    return (
        <div className="MenuCard">
            <img className="MenuCard-Img" src={`/images/${porps.item.img}`} alt={`Image of ${porps.item.name}`} />
            <ItemDetail {...porps}/>
        </div>
    )
}

function ItemDetail(props){
    return(
        <div className="ItemDetail">
            <h3 className="ItemDetail-Name">{props.item.name}</h3>
            <p className="ItemDetail-Description">{props.item.discription}</p>
            <div className="ItemDetail-InShoppingCart">
                <h2 className="ItemDetail-InShoppingCart-Price">{'$ '+props.item.price}</h2>
                <PlusAmountMinus {...props}/>
            </div>
        </div>
    )
}
// React.useEffect(()=>{

// },[])


function PlusAmountMinus(props){
    function pLus(i,a){
        console.log("pLus");
        props.func(i,a);
    }
    function mInus(i,a){
        console.log("mInus");
        props.func(i,a);
    }
    return (
       <div className="PlusAmountMinus">
        <div onClick={()=>pLus(props.item,1)} className="plus">
            <svg className="plusCircle" x="0" y="0"><circle cx="15" cy="15" r="14" stroke="wheat" strokeWidth=".5" fill="grey"/></svg>
            <h2 className="plusText" >+</h2>
        </div>
        <div className="amount">
            <svg className="amountCircle" height="50" width="50"><circle cx="25" cy="25" r="24" stroke="wheat" strokeWidth=".5" fill="Wheat"/></svg>
            <h3 className="amountText" >{props.item.amount}</h3>
        </div>
        <div onClick={()=>mInus(props.item,-1)} className="minus">
            <svg className="minusCircle" x="0" y="0"><circle cx="15" cy="15" r="14" stroke="wheat" strokeWidth=".5" fill="grey"/></svg>
            <h2 className="minusText" >-</h2>
        </div>
       </div>
    )
}