import './styles/GSushiMenu.css';
import Axios from 'axios';
import React from 'react';
import MenuCard from './MenuCard';

const baseURL= process.env.REACT_APP_BASE_URL

export default function ShoppingCart(cart){
    const [cartWiDetail, setCartWiDetail] = React.useState([]);

    React.useEffect(()=>{
        // Axios.get('http://192.168.1.193:3001/api/g_sushi')
        Axios.get(baseURL+'/g_sushi')
            .then((res)=>{console.log(res);
                setCartWiDetail(cart.cart.map((x)=>{
                    var findItem = res.data.find((y)=>y.item===x.item);
                    return {...findItem,amount: x.amount, img: findItem.name.toLowerCase().replaceAll(' ','_')+'.jpeg'};
                }));  
            });
    },[cart.cart]);

    return(
        <div className='GSushiMenu'>
            {cartWiDetail.map((item)=>{
                return(
                    <MenuCard key={item.item} 
                    {...{item:{...item}, func: cart.func}}/>
                )
            })}
        </div>
    )

}

// function name2ImgName(name){
//     const n = name|| '';
//     n = n.toLowerCase()+'.jpeg';
//     return n.replaceAll(' ','_');
// }