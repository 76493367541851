import './styles/NavBar.css';
import logo from'./images/sushi_logo.png';
import {NavLink} from 'react-router-dom';

export default function NavBar(props){
    
    return(
        <nav className='NavBar'>
            <NavLink className='NavLink-logo' to='/'>
                <img className='NavBar-logo' src={logo} alt='Sushi'/>
                <h1 className='NavBar-title'>Sumeshi</h1> 
            </NavLink>
            <NavLink className='NavLink-shoppingcart' to='/shopping_cart'>
                <div className='NavBar-shoppingcart'>
                    <i className='bi bi-cart3'/>
                    {props.amount>0 && <p className='NavBar-shoppingcart-bedge'>{props.amount}</p>}
                </div>
            </NavLink>
        </nav>
    )
}