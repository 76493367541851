import './styles/Main.css';
import GSushiMenu from './GSushiMenu';

export default function Main(cart){
    
    return(
        <div className='Main'>
            <GSushiMenu {...cart}/>
        </div>
    );
}