import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import NavBar from './components/NavBar';
import Main from './components/Main';
import ShoppingCart from './components/ShoppingCart';

import React from 'react';

export default function App(){
    const [cart, setCart] = React.useState([]);

    function cartChange(item, amount){
        setCart((prevCart)=>{
            var a;
            var pCart = prevCart.map((x)=>{
                if(x.item===item.item){
                    a = x.amount;
                    return{...x, amount: amount<0?(a?a+amount:0):a+amount};
                }
                return {...x};
            });
            if (a===undefined&&amount>0){
                return [...pCart,{...item,amount:amount}];
            }
            return pCart;
        });
    }

    const cartNFunction = {...{cart: [...cart], func: cartChange}}

    return (
        <div className='App'>
            <Router>
                <NavBar amount={cart.reduce((x,y)=>x+y.amount,0)} />
                <Routes>
                    <Route path='/' element={<Main {...cartNFunction}/>} />
                    <Route path='/shopping_cart' element={<ShoppingCart {...cartNFunction}/>} />
                </Routes>
            </Router>
        </div>
    );
}