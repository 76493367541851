import './styles/GSushiMenu.css'
import Axios from 'axios';
import React from 'react';
import MenuCard from './MenuCard';

const baseURL= process.env.REACT_APP_BASE_URL

export default function GSushiMenu(cart){
    const [gSushiMenu, setGSushiMenu] = React.useState([]);

    React.useEffect(()=>{
        Axios.get(baseURL+'/g_sushi')
            .then((res)=>{
                setGSushiMenu(res.data.map((x)=>{
                    var findItem = cart.cart.find((y)=>y.item===x.item);
                    return {...x,amount: findItem?findItem.amount:0 , img: x.name.toLowerCase().replaceAll(' ','_')+'.jpeg'};
                }))
            })
    },[cart.cart]);
    console.log(gSushiMenu);
    return(
        <div className='GSushiMenu'>
            {gSushiMenu.map((item)=>{
                return(
                    <MenuCard  Key={item.item}
                    {...{item:{...item}, func: cart.func}}/>
                )
            })}
        </div>
    )
}

// function name2ImgName(name){
//     const n = name|| '';
//     n = n.toLowerCase()+'.jpeg';
//     return n.replaceAll(' ','_');
// }